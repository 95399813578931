@import "variables";

@font-face {
  font-family: "Space Grotesk";
  src: url("@{font-path}/SpaceGrotesk/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("@{font-path}/SpaceGrotesk/SpaceGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("@{font-path}/SpaceGrotesk/SpaceGrotesk-SemiBold.woff")
    format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("@{font-path}/SpaceGrotesk/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
