﻿.clearfix {
    .clearfix();
}

* html .clearfix {
    height: 1%;
}

.clearfix() {
    display: block;

    &:after {
        content: "";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
}