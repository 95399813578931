﻿// basic style, all tags from rich text editor should be styled
// this stylesheet should be set as "editor" in "Sites" module in Kentico
// its also included in final bundle

@import "lesshat.less";
@import "variables.less";

html,
body {
  font-family: @font-primary;
  color: @color-text;
  font-size: @font-size-phone;
  line-height: 1.55;

  @media (min-width: @min-tablet) {
    font-size: @font-size-tablet;
  }

  @media (min-width: @min-desktop) {
    font-size: @font-size-desktop;
  }

  color: @color-text;
  background: @color-background;
  font-family: @font-primary;
}

// typography
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.6rem;
  font-weight: 600;
}

h1,
.h1 {
  font-size: 3.07rem;
  line-height: 1.2;
  text-align: center;
}

h2,
.h2 {
  font-size: 2.23rem;
  line-height: 1.25;
}

h3,
.h3 {
  font-size: 1.875rem;
  line-height: 1.3;
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.35;
}

h5,
.h5 {
  font-size: 1.2rem;
  line-height: 1.5;
}

h6,
.h6 {
  font-size: 1.15rem;
  line-height: 1.6;
}

p {
  margin-top: 0;
}

// links
a,
.btn-link {
  color: @color-text;
  text-decoration: underline;
  .transition(color 0.3s);

  &:hover {
    text-decoration: none;
  }
}

.btn-link {
  display: inline;
  background: none;
  border: none;
}

img {
  max-width: 100%;
}

input,
textarea,
select,
button {
  font-family: @font-primary;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.table,
table {
  display: table;
  width: 100%;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.modal-open {
  overflow: hidden;
}

/// to use this fearure make sure to add following html to page
/*<div class="turn-phone">
    <i class="ico-refresh-button"></i>
    <p class="message">Stránka se nejlépe prohlíží ve formátu na výšku. Otočte prosím své zařízení.</p>
</div>*/
.turn-phone {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  .align-items(center);
  .justify-content(center);
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  font-size: 1.2em;
  line-height: 1.4em;
  padding: 0 20px;

  .message {
    display: block;
  }

  i {
    .animation(rotate 5s linear 1s infinite normal);
    display: block;
    font-size: 2em;
    margin-bottom: 0.5em;
  }
}

@media screen and (orientation: landscape) and (max-width: 800px) and (min-width: 500px) {
  .turn-phone {
    .display(flex);
  }
}

@-moz-keyframes rotate {
  0% {
    .rotate(0deg);
  }

  5% {
    .rotate(90deg);
  }

  25% {
    .rotate(90deg);
  }

  30% {
    .rotate(180deg);
  }

  50% {
    .rotate(180deg);
  }

  55% {
    .rotate(270deg);
  }

  75% {
    .rotate(270deg);
  }

  80% {
    .rotate(360deg);
  }

  100% {
    .rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    .rotate(0deg);
  }

  5% {
    .rotate(90deg);
  }

  25% {
    .rotate(90deg);
  }

  30% {
    .rotate(180deg);
  }

  50% {
    .rotate(180deg);
  }

  55% {
    .rotate(270deg);
  }

  75% {
    .rotate(270deg);
  }

  80% {
    .rotate(360deg);
  }

  100% {
    .rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    .rotate(0deg);
  }

  5% {
    .rotate(90deg);
  }

  25% {
    .rotate(90deg);
  }

  30% {
    .rotate(180deg);
  }

  50% {
    .rotate(180deg);
  }

  55% {
    .rotate(270deg);
  }

  75% {
    .rotate(270deg);
  }

  80% {
    .rotate(360deg);
  }

  100% {
    .rotate(360deg);
  }
}

//usefull classes
.wrapper {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  &.about-us,
  &.contact {
    width: auto;
  }

  &.blog {
    width: auto;
    padding: 2.62rem;
  }
}

strong {
  font-weight: bold;
}

.bold-black-line {
  &:before {
    content: "";
    display: block;
    margin-top: 4rem;
    margin-bottom: 2rem;
    height: 2px;
    width: 120px;
    background-color: #000;
  }
}

.person {
  font-size: 1rem;
  .display(flex);
}

.filter {
  .display(flex);
  justify-content: center;
  .align-content(space-between);
  .flex-wrap(wrap);
  margin: 1.43rem 0 2.86rem;

  li {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.work {
  .filter {
    .active {
      color: @color-green;
      border: 1px @color-green solid;
    }
  }
}
