﻿// Styling rules for header

@import "lesshat.less";
@import "variables.less";

header {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  .display(flex);
  padding: 2.24rem 4.62rem;
  background-color: #fff;
}

.logo {
  display: flex;
  height: 42px;
  align-items: center;
  img {
    width: 100%;
    height: 56px;
  }
}

/*Mobile nav button*/
.menuButton(@width: 48, @color: #fff, @lineHeight: 3px, @spaceHeight: 4px) {
  display: block;
  width: @width;
  @height: (3 * @lineHeight) + (3 * @spaceHeight);
  height: @height;
  @position: (((3 * @lineHeight) + (3 * @spaceHeight)) - (3 * @lineHeight)) / 3;
  transform: translateZ(0);
  will-change: transform;

  .line,
  &:before,
  &:after {
    background-color: @color;
    content: "";
    display: block;
    height: @lineHeight;
    margin-left: 20%;
    .translate3d(0, @position + @spaceHeight, 0);
    transition: all 300ms ease 0s;
    width: 60%;
    border-radius: 2px;
  }

  &:before {
    .translate3d(0, @position, 0);
  }

  &:after {
    .translate3d(0, @position + (2 * @spaceHeight), 0);
  }

  &.active {
    &:before {
      .transform( rotate(45deg) translate3d(@height/2, @height/2, 0) );
    }

    &:after {
      .transform( rotate(-45deg) translate3d(-(@lineHeight + @spaceHeight) + 0.5, (@lineHeight + @spaceHeight) - 0.5, 0) );
    }

    .line {
      .opacity(0);
    }
  }
}

.nav-button {
  display: none;
}

.main-nav {
  .flex(1);
  font-size: 11px;
  text-align: right;

  li {
    display: inline-block;
    line-height: 42px;
    margin-right: 3.64rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-block;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      text-decoration: none;
      padding: 0.55rem 0;
      border-bottom: 1px solid #fff;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #000;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }

      &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }

    &.Highlighted a:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}

@media screen and (max-width: 1400px) {
  header {
    padding: 2.24rem 2.62rem;
  }
}

@media screen and (max-width: 900px) {
  header {
    .justify-content(space-between);
  }

  .nav-button {
    .menuButton(48px, @color-text);
    float: left;
    margin-right: 10px;
    padding: 13px 0 6px 0px;
    cursor: pointer;
    height: 34px;
  }

  .main-nav {
    position: fixed;
    display: none;
    background-color: #fff;
    left: 0;
    top: 94px;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 2rem;
    overflow-y: auto;

    li {
      display: block;
      line-height: 6;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: @max-phone) {
  .main-nav {
    font-size: 1.3rem;
  }
}
