﻿@import "variables";

@font-face {
  font-family: "icomoon";
  src: url("@{font-path}/icomoon.eot?4xiiai");
  src: url("@{font-path}/icomoon.eot?4xiiai#iefix") format("embedded-opentype"),
    url("@{font-path}/icomoon.ttf?4xiiai") format("truetype"),
    url("@{font-path}/icomoon.woff?4xiiai") format("woff"),
    url("@{font-path}/icomoon.svg?4xiiai#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"]:before,
[class*=" ico-"]:before,
[class^="ico-"]:after,
[class*=" ico-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-arrow-left {
  &:before {
    content: @ico-arrow-left;
  }
}

.ico-arrow-right {
  &:before {
    content: @ico-arrow-right;
  }
}

.ico-next {
  &:after {
    content: @ico-arrow-right;
  }
}

.ico-logo {
  &:before {
    content: @ico-logo;
  }
}

.ico-refresh-button {
  &:before {
    content: @ico-refresh-button;
  }
}
