// critical css
// only content "above the fold" should be styled here
@import "lesshat.less";
@import "variables.less";
// above the fold content - header is already linked,
// so slider, hero img or anything which user sees on page without scrolling (homepage only)

.about-us {
  font-size: 20px;

  &.wrapper {
    max-width: 1160px;
  }

  span {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    padding: 1rem 0 0.43rem;
  }

  strong {
    display: block;
  }

  .person {
    :first-child {
      margin-right: 4.285rem;
    }
  }

  .managers {
    margin: 6.43rem 0 5.71rem;

    p {
      padding: 1rem 10rem 1rem 0;
      line-height: 23px;
    }

    h2 {
      margin-bottom: 0.35rem;
    }

    .info {
      padding: 0 0 1rem 0;
    }

    .manager {
      .flex(1);
    }
  }

  .managers--new {
    margin: 6.43rem 0 30px -40px;
    max-width: none !important;
    h2 {
      padding-top: 20px;
      &.bold-black-line {
        &::before {
          content: normal;
        }
      }
    }
    .manager {
      border: 40px solid transparent;
      border-width: 0 0 40px 40px;
    }
    .description {
      pre {
        white-space: unset;
      }
    }
    @media (max-width: @min-tablet) {
      h2 {
        font-size: 20px;
      }
    }

    @media (min-width: @min-tablet) {
      flex-wrap: wrap;
      :first-child {
        margin-right: 0;
      }
      h2 {
        font-size: 20px;
        &::before {
          margin-top: 2rem;
        }
      }
      h3 {
        font-size: 18px;
      }
      .manager {
        flex: 0 0 33.33%;
        width: 33.33%;
      }
      .description p {
        line-height: 1.4;
        padding-right: 0;
        br {
          display: none;
        }
      }
    }
  }

  .grey {
    background-color: @color-light-grey;
    margin: 0 -2.62rem;
    padding: 0 2.62rem 8.57rem;

    .first {
      padding: 5.71rem 0 8rem;
      font-size: 30px;
      text-align: center;
    }

    .collaborators {
      p {
        padding: 0;
      }

      strong {
        margin: 2rem 0 0;
      }

      .collaborator {
        .flex(1);
      }
    }
  }
  .images {
    max-width: 670px;
    margin: 10px auto 20px;
    .display(flex);

    .image:first-child {
      .flex(2);
    }

    .image {
      .flex(1);
      line-height: 0;

      img {
        width: 100%;
      }
    }

    .small-image {
      .flex(1);
      .display(flex);
      .flex-direction(column);
    }
  }
  @media (max-width: @max-tablet) {
    &.wrapper {
      margin: 0 2.62rem;
    }

    .managers {
      p {
        padding-right: 0;
        line-height: 23px;
      }
    }
  }
  @media (max-width: @max-phone) {
    .person {
      display: block;

      :first-child {
        margin-right: 0;
      }

      .collaborator:first-child {
        margin: 0 0 10rem 0;
      }
    }

    .images {
      display: block;

      .small-image {
        display: block;

        img {
          width: 50%;
          float: left;
        }
      }
    }
  }

  @media (max-width: 440px) {
    .images {
      .small-image {
        img {
          width: 100%;
        }
      }
    }
  }
}
