// critical css
// only content "above the fold" should be styled here

@import 'lesshat.less';
@import 'variables.less';



// above the fold content - header is already linked,
// so slider, hero img or anything which user sees on page without scrolling (homepage only)

.work-detail{
    margin-bottom: 4.21rem;

    letter-spacing: 1px;
    .display(flex);

    .image-detail{
        .flex(2);
        line-height: 1rem;
        margin: 0 0 0 2.62rem;

        img{
            width: 100%;
        }
    }

    .detail-text{
        .flex(1);
        order: 2;

        .fixed{
            position: fixed;
            margin-left: 2.62rem;
            max-width: 340px;
            overflow-y: auto;
            max-height: calc(~"100vh - 160px");

            h1{ 
                margin: 2rem 0;
                font-size: 2.23rem;
                line-height: 1.25;
                text-align: left;
            }
            
            .back{
            color: @color-grey;
            line-height: 28px;
            
                &:before{
                    font-size: 15px;
                    margin: 0.36rem 1.43rem 0 0;
                    vertical-align: -21%;
                }
            }
            span{
                font-weight: bold;
                font-size: 11px;
            }
        }
        .arrows {
            position: fixed;
            bottom: 1.43rem;
            margin-left: 2.62rem;
        }

        .ico-arrow-left:before {
            padding-right: 0.71rem;
        }

        .project{
            &:after{
                //content: "\F102";
                padding-left: 10px;
                font-family: iconfont;
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: .2em;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                margin-left: .2em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            } 
        }
    }

    .filter{
        .flex-wrap(wrap);
        .justify-content(flex-start);
        margin: 2.36rem 0px 1.43rem;

        li{
            margin: 0 0.71rem 0.71rem 0;
            cursor: default;
            padding: 0.14rem 0.43rem;
        }
    }

    a{
        text-decoration: none;
        text-transform: uppercase;
        font-size: 11px
    }

    @media (max-width: @max-tablet){
        .detail-text{

            .fixed{
                margin-right: 2.62rem;
                
                .down{
                    font-size: 0;

                    &:before{
                        font-size: 15px;
                }
                    &:after{
                        font-size: 15px;
                    }
                }
            }
        }
        .filter{
            li{
                margin: 0.71rem 0.36rem 0;
            }
        }
    }

@media (max-width: @max-phone){
    display: block;
    .detail-text{
    margin: 0 2.14rem 0;

        .fixed{
            position: relative;
            margin-left: 0;
            max-width: none;

                .filter{
                    margin: 2.36rem 0 1.43rem -0.36rem;
                }

                .arrows{
                    position: relative;
                    top: 2.14rem;
                }
            }
        }

    .image-detail{
        margin: 5rem 2.62rem 0;
        }
    }
    
}

@media screen and (max-width: 640px) {
  body .work-detail .detail-text .arrows{
    margin: 0!important;
    left: 2.62rem;
    right: 2.62rem;
    bottom: 0;
    background-color:white;
    display: flex;
    flex-direction: column;
    .ico-next {
      text-align: right;
    }
    a {
      padding: 0.25rem 0;
    }
  }

}


body.lb-disable-scrolling {
    overflow: hidden;
  }
  
  .lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    display: none;
  }
  
  .lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
  }
  
  .lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 3px;
  
    /* Image border */
    border: 4px solid white;
  }
  
  .lightbox a img {
    border: none;
  }
  
  .lb-outerContainer {
    position: relative;
    *zoom: 1;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
  
    /* Background color behind image.
       This is visible during transitions. */
    background-color: white;
  }
  
  .lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
  }
  
  .lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(../images/loading.gif) no-repeat;
  }
  
  .lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  
  .lb-container > .nav {
    left: 0;
  }
  
  .lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
  }
  
  .lb-prev, .lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
  }
  
  .lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(../images/prev.png) left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  
  .lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  .lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(../images/next.png) right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }
  
  .lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  
  .lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    *zoom: 1;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .lb-data {
    padding: 0 4px;
    color: #ccc;
  }
  
  .lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }
  
  .lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
  }
  
  .lb-data .lb-caption a {
    color: #4ae;
  }
  
  .lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }
  
  .lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(../images/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  
  .lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
  