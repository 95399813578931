#app, .app-wrapper {
    height: 100%;
}

.mapouter {
    overflow:hidden;
    height:270px;
    width:1280px;
}

.gmap_canvas {
    background:none!important;
    height:270px;
    width:1280px;
}

.fade-enter {
    opacity: 0.01;
    transition: opacity ease 300ms;
}
.fade-enter.fade-enter-active {
    opacity: 1;
}

.fade-leave {
    opacity: 1;
    transition: opacity ease 150ms;
}
.fade-leave.fade-leave-active {
    opacity: 0.01;
}

.fade-appear {
    opacity: 0.01;
    transition: opacity ease 500ms;
}
.fade-appear.fade-appear-active {
    opacity: 1;
}

.filter {
    li {
        border: 1px solid transparent;
        user-select: none;
    }

    .active {
        color: @color-green;
        border: 1px @color-green solid;

        a {
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            color: #cad25b;
        }
    }
}

.underline-hover {
    position: relative;
    font-weight: normal !important;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -2px;
        left: 0;
        background-color: #000;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }

    &:hover:after {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    &.highlighted:after {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}