﻿// Styling rules for footer

@import "lesshat.less";
@import "variables.less";

footer {
  .display(flex);
  .align-items(center);
  background-color: #1c1f23;
  color: #fff;
  font-size: 11px;
  padding: 1.95rem 4.62rem;
}

.copyright {
  .flex(1);
  text-transform: uppercase;
  line-height: inherit;
  vertical-align: middle;
  margin-right: 1rem;
}

.footer-logo {
  color: #3e4044;
  height: 30px;
  line-height: 0;

  &:before {
    font-size: 5.07rem;
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}

.footer-link {
  margin-right: 2rem;
  flex: 1;

  a {
    display: inline-block;
    color: #fff;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1400px) {
  footer {
    padding: 2.31rem 2.62rem;
  }
}
