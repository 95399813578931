// critical css
// only content "above the fold" should be styled here

@import "lesshat.less";
@import "variables.less";

// above the fold content - header is already linked,
// so slider, hero img or anything which user sees on page without scrolling (homepage only)

.contact {
  padding: 0 2.14rem;

  #map {
    position: relative;
    height: 270px;
    margin-bottom: 3.57rem;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 270px;
    }
  }

  &-area {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &__content {
      flex: 0 1 auto;
      width: 100%;
      min-height: 7.85rem;
    }
  }

  &-person {
    .display(flex);
    margin: 0 0 7.14rem;

    li {
      .flex(1);
      a {
        text-decoration: none;
      }
    }

    h2 {
      margin-bottom: 0.71rem;
    }

    .bold-black-line {
      &:before {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  .other-place {
    .display(flex);
    margin: 8.57rem 0;

    h2 {
      .flex(1);

      &:after {
        content: "";
        display: block;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        height: 2px;
        width: 120px;
        background-color: #000;
      }
    }

    address {
      .flex(1);
    }
  }

  @media (max-width: @max-tablet) {
    &-area {
      flex-direction: row;

      &__content {
        width: 50%;
      }
    }

    &.wrapper {
      margin: 0 2.62rem 0 2.62rem;
    }

    .contact-person {
      margin: 3.57rem 0;
    }

    .other-place {
      margin: 3.57rem 0 -8rem;
    }
  }

  @media (max-width: @max-phone) {
    h1 {
      text-align: left;
    }
    &-area {
      flex-direction: column;

      &__content {
        width: 100%;
      }
    }
    .contact-person {
      display: block;
      margin: 0 0 2.14rem;

      .person {
        margin-top: 2.5rem;
      }

      :first-child {
        margin-bottom: 1.43rem;
      }
    }

    .other-place {
      display: block;
      margin: 2.86rem 0;
    }
  }
}
