﻿@import "variables";
@import "lesshat";

/*CSS3 animations*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1\9; /* IE9 only */
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1\9; /* IE9 only */
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    opacity: 1\9; /* IE9 only */
  }

  to {
    opacity: 1;
  }
}

/*Masterpage and homepage*/

html,
body,
form {
  height: 100%;
}

body {
  padding-top: 100.22px;
}

.home-body {
  font-family: @font-primary;
  .display(flex);
  .flex-direction(column);
}

.work-blocks-wrapper {
  .flex(1);
  .display(flex);
  .justify-content(space-between);
  margin-bottom: 3px;

  & > .work-block:first-child {
    .flex-basis(60%);
    margin-right: 3px;
  }
}

.work-block {
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;

  &:hover {
    .work-block-info {
      display: block;
    }
  }
}

.work-block-info {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 31, 35, 0.9);
  color: #fff;
  padding: 4.62rem;
  line-height: 2;
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;

  h2 {
    position: relative;

    &:before {
      content: "";
      display: block;
      margin-top: 1px;
      margin-bottom: 1.5rem;
      height: 2px;
      width: 120px;
      background-color: #fff;
    }
  }
}

.work-number {
  position: absolute;
  top: 2.62rem;
  right: 4.62rem;
  line-height: 1;
}

.work-blocks-right {
  .flex-basis(40%);
  .display(flex);
  .flex-direction(column);
  .justify-content(space-between);

  .work-block {
    .flex(1);

    &:first-child {
      margin-bottom: 3px;
    }
  }
}

main {
  min-height: calc(~"100vh - 247px");
  .flex(1);
  .display(flex);
  .flex-direction(column);
}

.news-wrapper {
  .display(flex);

  &.owl-carousel {
    .display(flex);
  }
}

.news-item {
  .flex(1);
  .display(flex);
  .justify-content(space-between);
  padding: 4.62rem;

  background-color: #f8f9fa;

  & > a {
    text-decoration: none;
    .flex-basis(40%);

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 1.6rem;
    line-height: 1.62;
    text-align: right;
  }

  p,
  & > div {
    .flex-basis(50%);
    line-height: 1.8;
    font-size: 1.2rem;
  }
}

.info-text {
  font-size: 20px;
  margin-bottom: 3rem;
}

//blog
.blog-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.blog-item {
  position: relative;
  display: block;
  flex: 0 1 33.33%;
  max-width: 33.33%;
  padding: 1.5rem;
  padding-bottom: 2.5rem;

  & > a {
    text-decoration: none;
    color: @color-green;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    margin-bottom: 1.5rem;
    color: @color-text;
  }

  .blog-summary {
    margin-bottom: 1.5rem;
    color: @color-grey;

    h4 {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .read-more {
    position: absolute;
    left: 1.5rem;
    bottom: 1.5rem;
    color: black;
  }
}

.blog-date {
  display: block;
  margin-bottom: 1rem;
}

.blog-content {
  img {
    max-width: 100%;
  }
}

.blog-image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 350px;
  margin-bottom: 2rem;

  @media screen and (max-width: @max-phone) {
    height: 150px;
  }

  img {
    width: 100%;
    max-width: 125%;
    height: 100%;
    transform-origin: 0 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    font-family: "object-fit: cover";
    vertical-align: middle;
    border-style: none;
    box-sizing: border-box;
  }
}

.blog-teaser {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;

  &.default {
    background-color: #ededed;

    img {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 68px;
    }
  }

  &.teaser {
    img {
      width: 100%;
      max-width: 125%;
      height: 100%;
      transform-origin: 0 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      font-family: "object-fit: cover";
      vertical-align: middle;
      border-style: none;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1400px) {
  .work-block-info,
  .news-item {
    padding: 2.62rem;
  }
}

@media screen and (max-width: @max-tablet) {
  .work-block-info {
    display: block;
    background: transparent;
    //background-color: rgba(0, 0, 0, .4);
    padding: 2.62rem;
  }

  .work-block-info > * {
    display: none;
  }

  .work-number {
    top: 2.62rem;
    right: 2.62rem;
  }
}

@media screen and (max-width: 900px) {
  .home-body,
  main {
    display: block;
  }

  .home-body {
    .work-block-info {
      position: relative;
      z-index: -1;
    }
  }

  .blog-item {
    flex-basis: 50%;
    max-width: 50%;
  }

  .work-blocks-wrapper {
    display: block;

    & > .work-block:first-child {
      margin-right: 0;
      margin-bottom: 3px;
    }
  }

  .work-block {
    text-decoration: none;
    min-height: 200px;
  }

  .news-wrapper {
    overflow-y: hidden;
    min-width: 0;
    flex-wrap: wrap;
  }

  .news-item {
    height: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .news-wrapper.owl-carousel .owl-stage {
    .display(flex);
    .flex-direction(row);
    padding-left: 5.8rem;
    margin-left: -5.8rem;

    &:after {
      display: none;
    }
  }

  .news-wrapper.owl-carousel .owl-item {
    float: none;

    &:first-child {
      .news-item {
        background-color: #f8f9fa;
      }
    }

    &:last-child {
      .news-item {
        padding-left: 7.42rem;
        margin-right: -5.8rem;
      }
    }
  }
}

@media screen and (max-width: @max-phone) {
  .blog-item {
    flex-basis: 100%;
    max-width: 100%;

    h2 {
      font-size: 1.8rem;
    }
  }
}

@media screen and (max-width: 400px) {
  .news-item {
    display: block;
  }
}
