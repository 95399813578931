﻿// global variables for all less stylesheets
// feel free to changed the values as you wish or add new-one
// but since this file is referenced in all stylesheets it shouldt produce any output
// otherwise rules would be included multiple-times
// (so no css rules shoud be written here, only variable declarations)

// resolutions
@min-desktop: 1260px;
@max-tablet: (@min-desktop - 1px);
@min-tablet: 641px;
@max-phone: (@min-tablet - 1px);
@min-phone: 320px;

@font-size-phone: 11px;
@font-size-tablet: 12px;
@font-size-desktop: 13px;

// dont set em, px or whatever values
// https://stackoverflow.com/questions/15132671/ems-for-line-height?answertab=votes#tab-top
@line-height: 1.3;

// colors
@color-text: #363a3e;
@color-background: #fff;
@color-primary: #3e89c2;
@color-green: #cad25b;
@color-grey: #b4b7bd;
@color-light-grey: #fafafa;
// other colors to suit your needs
@font-primary: "Space Grotesk", sans-serif;
@font-secondary: "Work Sans", sans-serif;

//icomoon
@font-path: "../font";

@ico-arrow-left: "\e900";
@ico-arrow-right: "\e901";
@ico-logo: "\e902";
@ico-refresh-button: "\e903";
