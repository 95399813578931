// critical css
// only content "above the fold" should be styled here

@import "lesshat.less";
@import "variables.less";

// above the fold content - header is already linked,
// so slider, hero img or anything which user sees on page without scrolling (homepage only)

.aspect-ratio(@width, @height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (@height / @width) * 100%;
  }

  > .content {
    position: absolute;
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    span:before {
      content: "@{width}";
    }

    span:after {
      content: "@{height}";
    }
  }
}

.work {
  &.wrapper {
    margin-bottom: 4.28rem;
    padding: 0 2.14rem;
  }

  .filter {
    li {
      margin: 0 0.71rem;
      padding: 0.43rem;

      a {
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          color: @color-green;
          transform: perspective(1px) translateZ(0);
          transition-duration: 0.5s;
          transform: perspective(1px) translateZ(0);
        }
      }
    }
  }

  .work-extract {
    .display(flex);
    flex-flow: row wrap;

    .work-link {
      width: calc(~"100% / 3 - 20px");
      .aspect-ratio(1, 1);
      display: block;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      text-align: left;
      border: 1px solid #fff;
      margin: 10px;
    }
  }

  #all-cat {
    display: none;
  }

  @media (max-width: @max-tablet) {
    font-size: @font-size-tablet;

    .work-extract {
      .work-link {
        width: calc(~"100% / 2 - 20px");
      }
    }
  }

  @media (max-width: @max-phone) {
    font-size: @font-size-phone;

    .work-extract {
      .work-link {
        width: 100%;
      }
    }

    .ul {
      display: inline-block;

      li {
        margin: 0.71rem 0;
      }
    }
  }
}
